import { FC, useEffect, useState } from "react";
import {
  countries as defaultCountries,
  CountryCode,
} from "@shinetools/geo-library";
import { uniqBy } from "ramda";

import { getAllowedCountryCodes } from "../utils";
import { CountryCallingCodeOption } from "./types";
import ReactSelect from "react-select";
import colors from "src/@chakra-ui/gatsby-plugin/foundations/colors";
import { Flex, Image } from "@chakra-ui/react";

type AvailableLanguages = "en" | "fr";

interface ListedCountry {
  callingCode: string;
  flag: string;
  name: Record<AvailableLanguages, string>;
  emoji: string;
}

const mapCountryToCallingCode = ({
  callingCode,
  flag,
}: Pick<ListedCountry, "callingCode" | "flag">) => ({
  flag,
  label: `+${callingCode}`,
  value: callingCode,
});

const sortByLabel = (
  a: Pick<ListedCountry, "callingCode">,
  b: Pick<ListedCountry, "callingCode">,
) => parseInt(a.callingCode, 10) - parseInt(b.callingCode, 10);

const formatOptionLabel: FC<CountryCallingCodeOption> = ({ flag, label }) => {
  return (
    <Flex alignItems="center">
      <Image
        height="15px"
        borderRadius="4px"
        marginRight="space-8"
        alt={label}
        src={flag}
        display={{ base: "none", md: "block" }}
      />
      <span>{label}</span>
    </Flex>
  );
};

const CountryCallingCodePicker: FC<{
  value: string;
  onChange: (countryCallingCode: string) => void;
}> = ({ onChange, value }) => {
  const [countries, setCountries] = useState<CountryCallingCodeOption[]>([
    mapCountryToCallingCode(defaultCountries.FR),
  ]);

  const getCountryList = async () => {
    try {
      const allowedCountryCodes = await getAllowedCountryCodes();
      const allowedCountries = allowedCountryCodes
        .map((countryCode) => {
          const country = defaultCountries[countryCode as CountryCode];
          return country;
        })
        .filter((country) => country !== undefined);

      // Some "countries" share the same phone code, e.g: +262 for some french overseas territories
      const uniqueCountries = uniqBy<ListedCountry, string>(
        (country) => country.callingCode,
      )(allowedCountries);

      const formattedCountries = uniqueCountries
        .sort(sortByLabel)
        .map(mapCountryToCallingCode);
      setCountries(formattedCountries);
    } catch (error) {}
  };

  useEffect(() => {
    getCountryList();
  }, []);

  return (
    <ReactSelect
      formatOptionLabel={formatOptionLabel}
      onChange={(option) => onChange(option?.value ?? "")}
      options={countries}
      value={countries.find((country) => country.value === value)}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base, { hasValue, isFocused, menuIsOpen }) => ({
          ...base,
          ":hover": {
            backgroundColor: colors["bg-input"],
            borderColor: (() => {
              if (menuIsOpen) {
                return colors["text-secondary"];
              }

              return colors["primary-yellow"];
            })(),
          },
          borderColor: (() => {
            if (isFocused || menuIsOpen) {
              return colors["text-secondary"];
            }

            return colors["primary-yellow"];
          })(),
          borderRadius: "8px",
          boxShadow: "none",
          fontWeight: hasValue ? 500 : 400,
          height: "2.5rem", // 40px
          transition: "all 0.2s ease-out",
        }),
        menu: (base) => ({
          ...base,
          marginTop: ".25rem",
        }),
        menuList: (base) => ({
          ...base,
          borderRadius: "8px",
        }),
        option: (base, { isDisabled, isFocused, isSelected }) => ({
          ...base,
          "&:last-of-type": {
            borderBottom: "0",
          },
          ":hover": {
            backgroundColor: colors["bg-input"],
          },
          backgroundColor:
            (isSelected || isFocused) && !isDisabled
              ? colors["bg-input"]
              : "white",
          borderBottom: "1px solid",
          borderBottomColor: colors["border-light"],
          cursor: isDisabled ? "not-allowed" : "pointer",
          padding: "8px 10px",
          color: colors["text-primary"],
        }),
        singleValue: (base) => ({
          ...base,
          color: colors["text-primary"],
          overflow: "visible",
        }),
        valueContainer: (base) => ({
          ...base,
          gap: "8px",
          height: "100%",
          padding: "0 12px",
        }),
      }}
    />
  );
};

export default CountryCallingCodePicker;
