import { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";

import { FormControl, Input } from "@chakra-ui/react";

import { PhoneInputProps } from "./types";
import CountryCallingCodePicker from "../CountryCallingCodePicker";

const PhoneInput: FC<PhoneInputProps> = ({
  countryCode,
  onUpdateCountryCode,
  onUpdatePhone,
  phone,
  error,
}) => {
  const localOnUpdateCountryCode = (countryCallingCode: string): void => {
    onUpdateCountryCode(countryCallingCode);
  };

  const localOnUpdatePhone = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onUpdatePhone(event.target.value);
  };

  const countryCallingCode = (
    <Box marginBottom="-1px" minWidth="110px">
      <CountryCallingCodePicker
        onChange={localOnUpdateCountryCode}
        value={countryCode}
      />
    </Box>
  );

  const inputProps = {
    autoFocus: true,
    inputMode: "tel",
    name: "phone",
    onChange: localOnUpdatePhone,
    type: "text",
    value: phone,
  } as const;

  return (
    <Flex gap="space-8" width="100%">
      <FormControl
        flex={1}
        maxWidth="130px"
        spacing="space-4"
        isInvalid={!!error}
      >
        {countryCallingCode}
      </FormControl>

      <FormControl flex={2} spacing="space-4" isInvalid={!!error}>
        <Input
          {...inputProps}
          focusBorderColor="grey-1"
          borderColor="yellow-0"
          backgroundColor="white"
          _hover={{ backgroundColor: "grey-4" }}
          _focus={{ borderColor: "grey-1", boxShadow: "none" }}
          _invalid={{ borderColor: "orange-800" }}
          paddingX="space-12"
        />
      </FormControl>
    </Flex>
  );
};

export default PhoneInput;
