import queryString from "query-string";

const parseJSON = <T>(response: Response): Promise<T> => {
  if (response.status === 204) {
    return {} as Promise<T>;
  }

  return response.json();
};

const enhancedFetch = <T>(
  url: RequestInfo,
  options: RequestInit = {},
): Promise<T> => {
  const params = {
    ...options,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      platform: "web",
      ...options.headers,
    },
  };

  if (typeof params.body !== "string") {
    params.body = JSON.stringify(params.body);
  }

  return fetch(url, params)
    .then((res: Response): Promise<T> => parseJSON<T>(res))
    .catch((err) => {
      throw err;
    });
};

const get = <T = JSONObject>(
  path: string,
  params?: JSONObject,
  options?: RequestInit,
): Promise<T> => {
  return enhancedFetch<T>(
    `${path}${params ? `?${queryString.stringify(params)}` : ""}`,
    options,
  );
};

interface CheckPhoneResponse {
  found: boolean;
  hasPasscode: boolean;
}

export const checkPhone = (phone: string): Promise<CheckPhoneResponse> =>
  get<CheckPhoneResponse>(
    `${process.env.GATSBY_API_HOST}/v2/authentication/phone/${phone}/check`,
  );

interface GetAllowedCountryCodesResponse {
  data: string[];
}
export const getAllowedCountryCodes = async () => {
  const { data } = await get<GetAllowedCountryCodesResponse>(
    `${process.env.GATSBY_API_HOST}/v2/authentication/phone/allowed_countries`,
  );
  return data;
};
